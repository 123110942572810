<template>
  <div class="uk-margin-top">
    <h4>Harga Kontrak Sapronak</h4>
    <p>
      Dibuat tanggal:  {{ contractPrice.updated_at.replaceAll("-", ' ') }}<br>
      <!-- Tanggal Berlaku: {{ contractPrice.effective_date.replaceAll("-", ' ') }} -->
    </p>
    <div
      class="uk-child-width-expand@s uk-grid-small"
      uk-grid
    >
      <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-4@l uk-width-1-4@xl">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1">
            Harga DOC/ekor
          </div>
          <div class="uk-width-1-1">
            <div class="uk-inline">
              <span
                class="uk-form-icon"
                uk-icon
              >Rp</span>
              <label
                class="uk-input"
                disabled
              >
                {{ thousandSeparator(contractPrice.doc_price) }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-4@l uk-width-1-4@xl">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1">
            Harga Pakan/kg
          </div>
          <div class="uk-width-1-1">
            <div class="uk-inline">
              <span
                class="uk-form-icon"
                uk-icon
              >Rp</span>
              <label
                class="uk-input"
                disabled
              >
                {{ thousandSeparator(contractPrice.feed_price) }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-4@l uk-width-1-4@xl">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1">
            Harga OVK/pcs
          </div>
          <div class="uk-width-1-1">
            <div class="uk-inline">
              <span
                class="uk-form-icon"
                uk-icon
              >Rp</span>
              <label
                class="uk-input"
                disabled
              >
                {{ thousandSeparator(contractPrice.ovk_price) }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-4@l uk-width-1-4@xl">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1">
            Tipe Harga
          </div>
          <div class="uk-width-1-1">
            <div class="uk-inline">
              <span
                class="uk-form-icon"
                uk-icon
              >Rp</span>
              <label
                class="uk-input"
                disabled
              >
                {{ contractPrice.price_type.replace("Rp","") }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h4 style="margin-top: 48px">
      Harga Kontrak Jual Ayam Hidup
    </h4>
    <p>Update terakhir: {{ formatDate(contractPrice.contract_price_chicken[0].updated_at) }}</p>
    <div
      class="uk-child-width-expand@s uk-grid-small"
      uk-grid
    >
      <div class="uk-width-1-2 uk-width-1-2@s uk-width-1-2@m uk-width-1-3@l uk-width-1-3@xl">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1">
            Bobot Rataan (kg)
          </div>
          <div
            v-for="(doc, i) in contractPrice.contract_price_chicken"
            :key="i"
            class="label-field uk-width-1-1"
          >
            <label
              class="uk-input"
              disabled
            >
              <div :class="changeString(doc)" />
              <div
                v-if="doc.max == '0,000'"
                :class="maxDot(doc)"
              />
              {{ doc.min }} - {{ doc.max }}
            </label>
          </div>
        </div>
      </div>
      <div class="uk-width-1-2 uk-width-1-2@s uk-width-1-2@m uk-width-2-3@l uk-width-2-3@xl">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 truncate-scroll">
            Harga Jual Ayam Hidup/Kg
          </div>
          <div
            v-for="(doc, i) in contractPrice.contract_price_chicken"
            :key="i"
            class="label-field uk-width-1"
          >
            <div class="uk-inline">
              <span
                class="uk-form-icon"
                uk-icon
              >Rp</span>
              <label
                class="uk-input"
                disabled
              >
                {{ thousandSeparator(doc.price) }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="uk-child-width-expand@s uk-grid-small uk-margin-top"
      uk-grid
    >
      <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-2@m uk-width-1-2@l uk-width-1-2@xl">
        <router-link
          class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
          to="/admin/harga-kontrak"
        >
          <img
            v-lazy="`${images}/icon/back.svg`"
            alt=""
            class="uk-margin-small-right"
          >
          Kembali
        </router-link>
      </div>
      <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-2@m uk-width-1-2@l uk-width-1-2@xl uk-text-right">
        <button
          v-if="isCanAccessUser(`edit`, `Harga Kontrak`)"
          class="uk-button uk-button-primary uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
          type="button"
          @click="goToEdit()"
        >
          <img
            v-lazy="`${images}/icon/edit.svg`"
            alt=""
            class="uk-margin-small-right"
          >
          Update Harga
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { PREFIX_IMAGE } from '@/utils/constant'
import { dateParanjeCompleteString, dateUtcParanjeString, thousandSeparator } from '@/utils/formater'
import { isCanAccess } from '@/utils/auth'

export default {
  props: {
    contractPrice: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      images: PREFIX_IMAGE
    }
  },
  watch: {
    contractPrice() {
      this.contractPrice.join_date = dateParanjeCompleteString(this.contractPrice.join_date)
      this.contractPrice.updated_at = dateParanjeCompleteString(this.contractPrice.updated_at)
    }
  },
  methods: {
    thousandSeparator(v){
      return thousandSeparator(v)
    },
    formatDate(date) {
      return dateUtcParanjeString(date)
    },
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    goToEdit() {
      this.$router.push(`/admin/harga-kontrak/edit/${this.contractPrice.id}`)
    },
    maxDot(doc) {
      doc.max = '...'
    },
    changeString(doc) {
      doc.min = this.changeDot(String(doc.min.toFixed(3))),
      doc.max = this.changeDot(String(doc.max.toFixed(3)))
    },
    changeDot(data) {
      data = data.replace(/\./g, ',')
      return data    }
  }
}
</script>

<style scoped>
h4 {
  font-weight: 500;
  font-size: 16px;
  color: #1F2E28;
  margin-bottom: 4px;
}
p {
  color: #1F2E28;
  margin: 0 0 24px;
}
.uk-input {
  background: #F0F5F3;
  border-radius: 6px;
}
.label-field {
  margin: 8px 0;
}


</style>
